<template>
  <div>
    <v-row no-gutters style="padding:0;">
      <v-col v-if="wWidth > 992" cols="12" style="padding:0;">
        <v-container
          id="testhtml"
          ref="testHtml"
          style="background: white; border-radius: 5px; position: relative;width:100%;"
          v-if="result !== null"
        >
          <v-card
            flat
            style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
          >
            <div
              style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
            >
              <v-toolbar-title class="overline"
                ><p style="font-size: 20px; margin: 0; font-weight: bold">
                  Detail Pemindahan Aset
                </p>
              </v-toolbar-title>
            </div>
            <div class="d-flex">
              <div>
                <div v-if="result.status === 1">
                  <!-- <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id === '1' || id === '29'
                ) !== undefined
              "
            > -->
                  <v-btn
                    v-if="isAllow"
                    @click="approve"
                    rounded
                    elevation="1"
                    outlined
                    color="green"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Approve
                  </v-btn>
                  <!-- </div> -->
                </div>
              </div>
              <div
                v-if="
                  getUserProfile.employee.company.plant_id ===
                    result.destination_company_id
                "
              >
                <v-btn
                  v-if="result.status === 2"
                  @click="process"
                  rounded
                  elevation="0"
                  color="indigo"
                  class="white--text font-weight-bold ma-2"
                  style="font-size:12px;"
                  :loading="loading"
                >
                  Proses
                </v-btn>
              </div>
              <div v-if="result.status === 1">
                <div
                  v-if="
                    result.created_by_name === getUserProfile.username &&
                      result.source_company_name ===
                        getUserProfile.employee.company.name
                  "
                >
                  <v-dialog v-model="dialog2" persistent width="760">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        elevation="1"
                        outlined
                        color="red"
                        class="white--text font-weight-bold ma-2"
                        style="font-size:12px;"
                      >
                        Batalkan
                      </v-btn>
                    </template>

                    <v-card style=" padding:20px; " class="text-center">
                      <v-row>
                        <v-col cols="12" class="text-h5 font-weight-bold">
                          BERIKAN ALASAN !
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="reason"
                            auto-grow
                            outlined
                            clearable
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="cancelRequest"
                            rounded
                            elevation="1"
                            outlined
                            color="red"
                            class="red--text font-weight-bold ma-2"
                            style="font-size:12px;"
                            :loading="loading"
                          >
                            Batalkan
                          </v-btn>
                          <v-btn
                            @click="dialog2 = false"
                            rounded
                            elevation="1"
                            outlined
                            color="indigo"
                            class="indigo--text font-weight-bold ma-2"
                            style="font-size:12px;"
                          >
                            Tutup
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div v-if="result.status === 2">
                <div
                  v-if="
                    getUserProfile.employee.company.name ===
                      result.source_company_name
                  "
                >
                  <v-btn
                    @click="downloadForm"
                    rounded
                    elevation="1"
                    outlined
                    color="indigo"
                    class="indigo--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Print
                  </v-btn>
                </div>
              </div>
              <v-btn
                @click="close"
                rounded
                elevation="0"
                text
                color="indigo"
                class="white--text font-weight-bold ma-2"
                style="font-size:12px;"
              >
                Kembali
              </v-btn>
            </div>
          </v-card>
          <v-row style="padding: 15px;">
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    No. Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="result.doc_no"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tgl. Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <!-- <v-text-field
                    :value="result.schedule_date"
                    dense
                    readonly
                    style="margin: 0"
                  /> -->
                  <v-text-field
                    :value="result.schedule_from"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tgl. Pengembalian
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="result.schedule_to"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Destinasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="result.destination_company_name"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <v-row style="padding: 15px; margin-top:10px;">
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    PIC
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="result.created_by_name"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Plant
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="result.source_company_name"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Department
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="result.source_department_name"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <!-- <v-col md="3" style="padding: 0"> -->
            <!-- <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Email
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense readonly style="margin: 0" />
              </v-col>
            </div> -->
            <!-- </v-col> -->
          </v-row>

          <v-row style="padding: 15px">
            <v-col md="12" style="padding: 0 10px">
              <v-card
                flat
                style="
            top: 0;
            background: white;
            margin-top: 20px;
            margin-bottom: 40px;"
              >
                <v-data-table
                  :items="result.item"
                  :headers="headTable(result.status)"
                  class="elevation-1 mx-auto"
                  style="width:100%; margin-bottom:10px; border:1px solid #e0e0e0"
                  :items-per-page="10"
                  hide-default-footer
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <div style="padding: 0; display: flex; flex-direction: row">
                      {{ item.lot !== null ? item.lot.asset.name : 'null' }}
                    </div>
                  </template>
                  <template v-slot:[`item.code`]="{ item }">
                    <div style="padding: 0; display: flex; flex-direction: row">
                      {{ item.lot !== null ? item.lot.asset.code : 'null' }}
                    </div>
                  </template>
                  <template v-slot:[`item.lot_no`]="{ item }">
                    <div style="padding: 0; display: flex; flex-direction: row">
                      {{ item.lot !== null ? item.lot.lot_no : 'null' }}
                    </div>
                  </template>
                  <template v-slot:[`item.new_lot_no`]="{ item }">
                    <div style="padding: 0; display: flex; flex-direction: row">
                      {{ item.new_lot_no }}
                    </div>
                  </template>
                  <template v-slot:[`item.plant`]="{ item }">
                    <div style="padding: 0; display: flex; flex-direction: row">
                      {{ item.lot !== null ? item.lot.company_name : 'null' }}
                    </div>
                  </template>
                  <template v-slot:[`item.placed_location_name`]="{ item }">
                    <!-- ll -->
                    <div style="padding: 0; display: flex; flex-direction: row">
                      {{ item.placed_location_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <div
                      :style="
                        `padding: 0; display: flex; flex-direction: row; color:${color(
                          item.status
                        )};`
                      "
                    >
                      {{ itemStatus(item.status) }}
                    </div>
                  </template>
                  <template v-slot:[`item.received_by_name`]="{ item }">
                    <div
                      :style="
                        `padding: 0; display: flex; flex-direction: row; color:${color(
                          item.status
                        )};`
                      "
                    >
                      {{ item.received_by_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.rejected_by_name`]="{ item }">
                    <div
                      :style="
                        `padding: 0; display: flex; flex-direction: row; color:${color(
                          item.status
                        )};`
                      "
                    >
                      {{ item.rejected_by_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.placed_location`]="{ item }">
                    <div style="padding: 0; display: flex; flex-direction: row">
                      <div
                        style="height:40px;width:150px;"
                        v-if="item.status === 0"
                      >
                        <v-autocomplete
                          v-if="item.lot !== null"
                          v-model="item.lot.placed_location"
                          :items="getDropdownLocation"
                          item-text="name"
                          item-value="id"
                          return-object
                          outlined
                          dense
                          style="margin: 0"
                          clearable
                          :disabled="item.lot.status === -1"
                        ></v-autocomplete>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.reject`]="{ item }">
                    <div
                      v-if="item.status === 0"
                      style="padding: 0; display: flex; flex-direction: row"
                    >
                      <v-checkbox
                        v-if="item.lot !== null"
                        v-model="item.lot.status"
                        :value="-1"
                        color="red"
                        style=" margin:0; height:25px; padding:0;"
                        :disabled="item.lot.placed_location"
                      ></v-checkbox>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="padding: 15px">
            <v-col md="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tujuan Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-textarea
                    :value="result.description"
                    dense
                    auto-grow
                    outlined
                    readonly
                  />
                </v-col>
              </div>
            </v-col>

            <v-col md="4" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Status dokumen
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    outlined
                    :value="movementStatus(result.status) || '-'"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col v-if="result.status === -2" md="8" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Alasan pembatalan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    outlined
                    :value="result.confirm_note || '-'"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <hr />
          <div>
            <div
              style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
            >
              <v-toolbar-title class="overline"
                ><p style="font-size: 16px; margin: 0; font-weight: bold">
                  Approvment
                </p>
              </v-toolbar-title>
            </div>
            <v-row style="padding: 15px">
              <v-col md="12" style="padding: 0">
                <v-data-table
                  :headers="approvalHeaders"
                  :items="matrix"
                  :loading="matrix.length === 0"
                  :items-per-page="10"
                  class="elevation-1 mx-auto"
                  style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
                  hide-default-footer
                >
                  <template v-slot:[`item.approver_user`]="{ item }">
                    <div style="cursor:pointer;" v-if="item">
                      <v-chip
                        label
                        outlined
                        small
                        style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                        v-for="(item, index) in item.approver_user"
                        :key="index"
                      >
                        {{ item.user.name }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:[`item.approved`]="{ item }">
                    <div
                      v-for="(items, index) in item.approver_user"
                      :key="index"
                    >
                      <v-chip
                        v-if="items.status === 1"
                        label
                        outlined
                        small
                        style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                      >
                        {{ items.user.name }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:[`item.rejected`]="{ item }">
                    <div
                      v-for="(items, index) in item.approver_user"
                      :key="index"
                    >
                      <v-chip
                        v-if="items.status === -1"
                        label
                        outlined
                        small
                        style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                      >
                        {{ items.user.name }}
                      </v-chip>
                    </div>
                  </template>
                </v-data-table>
                <div style="text-align:right; padding-right:5px;">
                  <v-btn
                    v-if="
                      getUserProfile.level.find(
                        ({ application }) => application.id === 1
                      ) !== undefined
                    "
                    color="grey"
                    tile
                    dark
                    small
                    dense
                    style="font-size:10px; width:100px;"
                    @click="fetch"
                    :loading="loading"
                  >
                    <span>Fetch Matrix</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col v-if="wWidth < 992" cols="12" style="padding:0;">
        <v-container
          style="width:100%;height:92vh;overflow:auto; margin: auto; border-radius: 5px; background:rgba(255,255,255,.9 ); padding:5px 15px 15px 15px;"
        >
          <v-row
            no-gutters
            style="width:100%; position:fixed; bottom:0; left:0;z-index:2; background:white;"
          >
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;"
            >
              <h5 style="font-weight:bold;margin:auto;"></h5>
            </v-col>
            <div style="width:100%;">
              <div
                v-if="result !== null"
                class="d-flex justify-center align-center"
                style="width:100%;background:white;"
              >
                <div>
                  <div v-if="result.status === 1">
                    <!-- <div
              v-if="
                getUserProfile.level.find(
                  ({ id }) => id === '1' || id === '29'
                ) !== undefined
              "
            > -->
                    <v-btn
                      v-if="isAllow"
                      @click="approve"
                      :loading="loading"
                      rounded
                      elevation="1"
                      outlined
                      color="green"
                      class="green--text font-weight-bold ma-2"
                      style="font-size:12px;"
                    >
                      Approve
                    </v-btn>
                    <!-- </div> -->
                  </div>
                </div>
                <div v-if="result.status === 1">
                  <div
                    v-if="
                      result.created_by_name === getUserProfile.username &&
                        result.source_company_name ===
                          getUserProfile.employee.company.name
                    "
                  >
                    <v-dialog v-model="dialog2" persistent width="760">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          rounded
                          outlined
                          elevation="1"
                          color="red"
                          class="red--text font-weight-bold ma-2"
                          style="font-size:12px;"
                        >
                          Batalkan
                        </v-btn>
                      </template>

                      <v-card style=" padding:20px; " class="text-center">
                        <v-row>
                          <v-col cols="12" class="text-h5 font-weight-bold">
                            BERIKAN ALASAN !
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="reason"
                              auto-grow
                              outlined
                              clearable
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              @click="cancelRequest"
                              rounded
                              elevation="1"
                              outlined
                              color="red"
                              class="red--text font-weight-bold ma-2"
                              style="font-size:12px;"
                            >
                              Batalkan
                            </v-btn>
                            <v-btn
                              @click="dialog2 = false"
                              rounded
                              elevation="1"
                              outlined
                              color="indigo"
                              class="indigo--text font-weight-bold ma-2"
                              style="font-size:12px;"
                            >
                              Tutup
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
                <div v-if="result.status === 2">
                  <div
                    v-if="
                      getUserProfile.employee.company.name ===
                        result.source_company_name
                    "
                  >
                    <v-btn
                      @click="downloadForm"
                      rounded
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold ma-2"
                      style="font-size:12px;"
                      :loading="loading"
                    >
                      Print
                    </v-btn>
                  </div>
                </div>
                <div
                  v-if="
                    getUserProfile.employee.company.plant_id ===
                      result.destination_company_id
                  "
                >
                  <!-- <div
                v-if="
                  getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
              > -->
                  <v-btn
                    v-if="result.status === 2"
                    @click="process"
                    rounded
                    elevation="0"
                    color="indigo"
                    class="white--text font-weight-bold ma-2"
                    style="font-size:12px;"
                    :loading="loading"
                  >
                    Proses
                  </v-btn>
                  <!-- </div> -->
                </div>
                <v-btn
                  @click="close"
                  rounded
                  text
                  class="indigo--text font-weight-bold ma-2"
                  style="font-size:12px;"
                >
                  Kembali
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-expansion-panels
            v-model="panel"
            multiple
            class="mb-1"
            elevation="1"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                style="font-weight: bold"
              >
                Detail Pemindahan
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  v-if="result !== null"
                  no-gutters
                  style="padding: 0; margin-top:10px;"
                >
                  <v-col cols="12" md="6" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          No. Pemindahan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          dense
                          :value="result.doc_no || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Tgl. Pemindahan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <!-- <v-text-field
                          dense
                          :value="result.schedule_date || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        /> -->
                        <v-text-field
                          dense
                          :value="result.schedule_from || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Tgl. Pengembalian
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          dense
                          :value="result.schedule_to || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Destinasi
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          dense
                          :value="result.destination_company_name || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          PIC
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          dense
                          :value="result.created_by_name || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Plant
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          dense
                          :value="result.source_company_name || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Tujuan pemindahan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-textarea
                          :value="result.description || '-'"
                          dense
                          auto-grow
                          outlined
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Status dokumen
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          outlined
                          dense
                          :value="movementStatus(result.status) || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                  <v-col
                    v-if="result.status === -2"
                    cols="12"
                    md="6"
                    style="padding: 0"
                  >
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                        >
                          Alasan pembatalan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          outlined
                          dense
                          :value="result.confirm_note || '-'"
                          readonly
                          style="margin: 0;font-size:12px;"
                        />
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                style="font-weight: bold"
                >Item yang dipindahkan
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="result !== null">
                  <v-row no-gutters style="padding: 0;">
                    <v-col
                      v-for="(item, index) in result.item"
                      :key="index"
                      cols="12"
                      md="6"
                      style="padding:0;"
                    >
                      <v-card
                        :color="color2(item.status)"
                        elevation="2"
                        tile
                        outlined
                        style="padding: 10px 10px 0 10px; margin-bottom:20px;"
                      >
                        <v-row no-gutters style="padding: 15px 10px;">
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.lot.lot_no || '-'"
                              readonly
                              label="Lot"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.new_lot_no || '-'"
                              readonly
                              label="New Lot"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.lot.asset.name || '-'"
                              readonly
                              label="Nama Aset"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.lot.asset.code || '-'"
                              readonly
                              label="Kode"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.lot.company_name || '-'"
                              readonly
                              label="Kepemilikan"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.placed_location_name || '-'"
                              readonly
                              label="Lokasi terima"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.received_by_name || '-'"
                              readonly
                              label="Diterima oleh"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="item.rejected_by_name || '-'"
                              readonly
                              label="Ditolak oleh"
                              style="margin: 0;font-size:12px;"
                            />
                          </v-col>
                          <v-col cols="12" style="padding: 5px 0;">
                            <v-text-field
                              dense
                              :value="itemStatus(item.status) || '-'"
                              readonly
                              label="Status"
                              style="margin: 0; height:40px;font-size:12px;"
                            />
                          </v-col>
                          <v-col
                            v-if="result.status === 2"
                            cols="12"
                            md="6"
                            style="padding: 5px 0;"
                          >
                            <div
                              v-if="
                                getUserProfile.employee.company.plant_id ===
                                  result.destination_company_id
                              "
                            >
                              <div v-if="item.status === 0">
                                <v-autocomplete
                                  v-if="item.lot !== null"
                                  v-model="item.lot.placed_location"
                                  :items="getDropdownLocation"
                                  item-text="name"
                                  item-value="id"
                                  return-object
                                  outlined
                                  dense
                                  style="margin: 0;height:40px;font-size:12px;"
                                  label="-Terima di gudang -"
                                  clearable
                                  :disabled="item.lot.status === -1"
                                ></v-autocomplete>
                              </div>
                            </div>
                          </v-col>
                          <v-col
                            v-if="result.status === 2"
                            cols="12"
                            md="6"
                            style="padding: 5px 0;"
                          >
                            <div
                              v-if="
                                getUserProfile.employee.company.plant_id ===
                                  result.destination_company_id
                              "
                            >
                              <div
                                v-if="item.status === 0"
                                style="padding: 0; display: flex; flex-direction: row; justify-content:felx-start; align-items:center; "
                              >
                                <v-checkbox
                                  v-if="item.lot !== null"
                                  v-model="item.lot.status"
                                  :value="-1"
                                  color="red"
                                  style=" margin:0; height:25px; padding:0;"
                                  :disabled="item.lot.placed_location"
                                ></v-checkbox>
                                <p
                                  style="margin-bottom:0; margin-right:15px; font-size:14px;"
                                >
                                  Tolak
                                </p>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel style="margin-bottom:50px;">
              <v-expansion-panel-header
                expand-icon="mdi-menu-down"
                style="font-weight: bold"
                >Approval
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="result !== null">
                  <v-row no-gutters style="padding: 0;">
                    <v-col md="12" style="padding: 0">
                      <v-data-table
                        :headers="approvalHeaders"
                        :items="matrix"
                        :loading="matrix.length === 0"
                        :items-per-page="10"
                        class="elevation-1 mx-auto"
                        style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
                        hide-default-footer
                      >
                        <template v-slot:[`item.approver_user`]="{ item }">
                          <div style="cursor:pointer;" v-if="item">
                            <v-chip
                              label
                              outlined
                              small
                              style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                              v-for="(item, index) in item.approver_user"
                              :key="index"
                            >
                              {{ item.user.name }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.approved`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                          >
                            <v-chip
                              v-if="items.status === 1"
                              label
                              outlined
                              small
                              style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                            >
                              {{ items.user.name }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.rejected`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                          >
                            <v-chip
                              v-if="items.status === -1"
                              label
                              outlined
                              small
                              style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                            >
                              {{ items.user.name }}
                            </v-chip>
                          </div>
                        </template>
                      </v-data-table>
                      <div style="text-align:right; padding-right:5px;">
                        <v-btn
                          v-if="
                            getUserProfile.level.find(
                              ({ application }) => application.id === 1
                            ) !== undefined
                          "
                          color="grey"
                          tile
                          dark
                          small
                          dense
                          style="font-size:10px; width:100px;"
                          @click="fetch"
                          :loading="loading"
                        >
                          <span>Fetch Matrix</span>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-col>
    </v-row>
    <div style="position:fixed; z-index:2; bottom:10px; right:0;">
      <Scanner
        @lotProcess="lotProcess"
        :page="'movementdetail'"
        @loadPage="detail"
      />
    </div>
    <div style="height:0; overflow:hidden;">
      <lot-moving-process
        :result="lot_item"
        :movementId="movementId"
        @loadPage="detail"
      />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  name: 'Movement-Detail',
  components: {
    Scanner: () => import('../../../home/components/Scanner.vue'),
    LotMovingProcess: () => import('../../movement/components/ProcessLot.vue')
  },
  data() {
    return {
      oAuth: buildType.apiURL('oauth'),
      asset: buildType.apiURL('asset'),
      panel: [0, 1],
      dialog: false,
      dialog2: false,
      form: {},
      loading: false,
      result: null,
      reason: '',
      headers: [
        {
          text: 'Nama Aset',
          value: 'name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kode Aset',
          value: 'code',
          align: 'left',
          sortable: false
        },
        { text: 'No. Lot', value: 'lot_no', align: 'left', sortable: false },
        {
          text: 'New Lot',
          value: 'new_lot_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kepemilikan',
          value: 'plant',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lokasi terima',
          value: 'placed_location_name',
          align: 'left',
          width: '5%',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          align: 'left',
          sortable: false
        }
        // {
        //   text: 'Jml. Dipinjamkan',
        //   value: 'total',
        //   align: 'left',
        //   sortable: false
        // }
      ],
      headers2: [
        {
          text: 'Nama Aset',
          value: 'name',
          align: 'left',
          width: '25%',
          sortable: false
        },
        {
          text: 'Kode Aset',
          value: 'code',
          align: 'left',
          width: '10%',
          sortable: false
        },
        {
          text: 'No. Lot',
          value: 'lot_no',
          align: 'left',
          width: '15%',
          sortable: false
        },
        {
          text: 'New. Lot',
          value: 'new_lot_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kepemilikan',
          value: 'plant',
          align: 'left',
          width: '20%',
          sortable: false
        },
        {
          text: 'Lokasi terima',
          value: 'placed_location_name',
          align: 'left',
          width: '5%',
          sortable: false
        },
        {
          text: 'Gudang',
          value: 'placed_location',
          align: 'left',
          width: '20%',
          sortable: false
        },
        {
          text: 'Reject',
          value: 'reject',
          align: 'left',
          width: '5%',
          sortable: false
        },
        {
          text: 'Diterima',
          value: 'received_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Ditolak',
          value: 'rejected_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          align: 'left',
          sortable: false
        }
      ],
      headers3: [
        {
          text: 'Nama Aset',
          value: 'name',
          align: 'left',
          width: '25%',
          sortable: false
        },
        {
          text: 'Kode Aset',
          value: 'code',
          align: 'left',
          width: '10%',
          sortable: false
        },
        {
          text: 'No. Lot',
          value: 'lot_no',
          align: 'left',
          width: '15%',
          sortable: false
        },
        {
          text: 'New Lot',
          value: 'new_lot_no',
          align: 'left',
          width: '15%',
          sortable: false
        },
        {
          text: 'Kepemilikan',
          value: 'plant',
          align: 'left',
          width: '20%',
          sortable: false
        },
        {
          text: 'Lokasi terima',
          value: 'placed_location_name',
          align: 'left',
          width: '5%',
          sortable: false
        },
        {
          text: 'Diterima',
          value: 'received_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Ditolak',
          value: 'rejected_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'status',
          value: 'status',
          align: 'left',
          sortable: false
        }
      ],

      approvalHeaders: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],

      isAllow: false,
      matrix: [],
      wWidth: window.innerWidth,
      lot_item: null,
      movementId: null
    }
  },
  mounted() {
    this.detail()
    window.scrollTo(0, 0)
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownLocation'])
  },
  methods: {
    ...mapActions([
      'dropdownLocation',
      'documentAuthority',
      'approvalMatrixDocumentList',
      'fetchMatrix'
    ]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    downloadForm() {
      this.loading = true
      axios({
        url: `${this.asset}movement/generate_receipt_pdf/${this.result.id}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          this.loading = false
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `AMS-${this.result.doc_no}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.showMsgDialog(
            'error',
            'Printing failed, please contact an Admin',
            false
          )
        })
    },
    detail() {
      this.result = null
      axios
        .get(`${this.asset}movement/detail/${this.$route.params.id}}`)
        // .get(`${this.asset}movement/detail/${this.moveId.id}}`)
        .then(res => {
          console.log(res)
          this.result = res.data.data
          this.dropdownLocation(res.data.data.destination_company_id)
          // this.dropdownLocation(this.getUserProfile.employee.company.id)
          this.authorityCheck(res.data.data)
          this.matrixCheck(res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    // clickOutside() {
    //   this.$emit('close')
    // },
    close() {
      // this.$emit('close')
      // this.dialog = false
      this.$router.push('/asset-management/pemindahan-aset')
    },
    process() {
      const newArr = []
      for (let i = 0; i < this.result.item.length; i++) {
        if (this.result.item[i].lot.placed_location !== undefined) {
          if (this.result.item[i].lot.placed_location !== null) {
            newArr.push({
              id: this.result.item[i].id,
              lot_id: this.result.item[i].lot.id,
              lot_no: this.result.item[i].lot.lot_no,
              placed_location_id: this.result.item[i].lot.placed_location.id,
              placed_location_name: this.result.item[i].lot.placed_location.name
            })
          }
        }

        if (this.result.item[i].lot.status !== undefined) {
          if (this.result.item[i].lot.status !== null) {
            if (this.result.item[i].lot.status === -1) {
              newArr.push({
                id: this.result.item[i].id,
                lot_id: this.result.item[i].lot.id,
                lot_no: this.result.item[i].lot.lot_no,
                status: this.result.item[i].lot.status
              })
            }
          }
        }
      }

      if (newArr.length > 0) {
        this.showMsgDialog(
          'question',
          'Terima permintaan, data yang tidak dipilih letak penyimpanannya akan direject otomatis',
          true
        ).then(res => {
          if (res.isConfirmed) {
            this.createNewForm(newArr)
          }
        })
      } else {
        this.showMsgDialog(
          'warning',
          'Ada data yang belum terisi dengan benar',
          false
        )
      }
    },
    createNewForm(arr) {
      const newForm = {
        id: this.result.id,
        item: arr
      }
      this.submit(newForm)
    },
    submit(form) {
      this.loading = true
      axios
        .post(`${this.asset}movement/receive`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
          // this.$emit('loadPage')
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },

    approve() {
      const form = {
        document_id: this.result.approval_matrix[0].document_id,
        status: 1
      }
      this.save(form)
    },
    save(form) {
      this.loading = true
      axios
        .post(`${this.asset}movement/confirm`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.detail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
          // this.$emit('loadPage')
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    authorityCheck(item) {
      const matrixId = item.approval_matrix[0].document_id
      axios
        .get(
          `${this.oAuth}approval_matrix/document/allow_user?document_id=${matrixId}&application_id=18`
        )
        .then(res => {
          if (res.data.status_code !== '-99') {
            if (res.data.is_allow_approve !== 0) {
              this.isAllow = true
            } else {
              this.isAllow = false
            }
          } else {
            this.isAllow = false
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin',
            false
          )
        })
    },
    matrixCheck(item) {
      axios
        .get(
          `${this.oAuth}approval_matrix/document/list?limit=100&offset=0&keyword=&application_id=18&table_name=${item.approval_matrix[0].table_name}&document_id=${item.approval_matrix[0].document_id}`
        )
        .then(res => {
          if (res.data.status_code === '00') {
            this.matrix = res.data.data
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin',
            false
          )
        })
    },
    fetch() {
      this.matrix = []
      const form = {
        act: 'fetch_matrix',
        document_id: this.result.approval_matrix[0].document_id,
        document_no: this.result.doc_no,
        application_id: 18,
        table_name: this.result.approval_matrix[0].table_name
      }
      this.loading = true
      axios
        .post(`${this.oAuth}approval_matrix/document/save`, form)
        .then(res => {
          if (res.status_code !== '-99') {
            this.showMsgDialog('success', res.status_msg, false)
            this.detail()
          } else {
            if (res.status_msg === 'Parameter value has problem') {
              this.showMsgDialog('error', res.error_msg[0].msg, false)
            } else {
              this.showMsgDialog('error', res.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin',
            false
          )
          this.loading = false
        })
    },
    cancelRequest() {
      this.showMsgDialog('question', 'Batalkan sekarang', true).then(res => {
        if (res.isConfirmed) {
          const form = {
            id: this.result.id,
            confirm_note: this.reason
          }
          this.loading = true
          axios
            .post(`${this.asset}movement/cancel`, form)
            .then(res => {
              if (res.data.status_code !== '-99') {
                this.reason = ''
                this.showMsgDialog('success', res.data.status_msg, 'false')
              } else {
                this.showMsgDialog('warning', res.data.status_msg, 'false')
              }
              this.detail()
              this.loading = false
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        }
      })
    },
    headTable(v) {
      switch (v) {
        case 1:
          return this.headers
        case 2:
          if (
            this.getUserProfile.employee.company.plant_id ===
            this.result.destination_company_id
          ) {
            return this.headers2
          }
          return this.headers3
        default:
          return this.headers
      }
    },
    itemStatus(v) {
      switch (v) {
        case -1:
          return 'Rejected'
        case 0:
          return 'Pending'

        case 1:
          return 'Received'
        default:
          return 'null'
      }
    },
    color(v) {
      switch (v) {
        case -1:
          return 'red'

        case 0:
          return 'grey'

        case 1:
          return 'green'

        default:
          return 'black'
      }
    },
    color2(v) {
      switch (v) {
        case -1:
          return 'hsla(10, 90%, 60%, 0.3)'

        case 1:
          return 'hsla(100, 50%, 50%, 0.3)'

        default:
          return 'white'
      }
    },
    movementStatus(v) {
      switch (v) {
        case -2:
          return 'Canceled'

        case 1:
          return 'Pending'

        case 2:
          return 'Approved'

        default:
          return 'null'
      }
    },
    lotProcess(v) {
      if (this.result.item.find(({ lot }) => lot.lot_no === v) !== undefined) {
        this.lot_item = this.result.item.find(({ lot }) => lot.lot_no === v)
        this.movementId = this.result.id
        setTimeout(() => {
          document.getElementById('processLot').click()
        }, 500)
      } else {
        setTimeout(() => {
          this.showMsgDialog('info', 'Lot tidak terdaftar di form ini', false)
        }, 500)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap
  padding: 10px
</style>
